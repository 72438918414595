@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light|Julius+Sans+One|Anton);
.header {
  height: auto;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.font-sil {
  font-family: 'Shadows Into Light', cursive;
}

.font-julius {
  font-family: 'Julius Sans One', sans-serif;
}

.font-anton {
  font-family: 'Anton', sans-serif;
}

.slidea {
  color: white;
}

@media screen and (max-width: 767px) {
  .slidea .margin-4x {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .slidea .padding-4x {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

#slide-beast-text {
  margin: 0;
}

#slide-mode-text {
  margin-top: -2rem;
}

#slide-on-text {
  font-size: 8rem;
  margin-top: -1.75rem;
  margin-left: -1rem;
}

#slide-limits-text {
  font-size: 6rem;
  margin-top: -2rem;
}

#slide-fitness-text {
  margin-top: -3.5rem;
}

#slide-towel-description ul {
  color: gray;
  padding-left: 0;
  margin-top: 2rem;
  list-style: none;
}

#slide-towel-description ul li {
  margin-top: 0.5rem;
}
